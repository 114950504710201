.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #333;
}

.App-link {
    color: #61dafb;
}

#token {
    transition: 0.5s ease;
}

body {
    background: #252b35;
    touch-action: pan-y;
    background-image: url("/top-shape.svg"), url("/bottom-shape.svg");
    background-position: right top, right bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: contain, contain;
}

@media screen and (min-width: 992px) {
    body {
        background-position: 0% -50%, 0% 150%;
    }
}

@media screen and (orientation: landscape) {
  body {
      background-position: none;
  }
}

.progress-bar {
    float: bottom;
    width: 100%;
    margin: 0;
    height: 4px;
}

.progress-bar-progress {
    float: left;
    border-radius: 2px;
    height: 100%;
    transition: 1s linear;
}

.modal-btn {
    position: fixed;
    width: 60px;
    height: 60px;
    top: 20px;
    right: 5px;
    color: white;
    text-align: center;
    font-size: 20px;
}

.modal {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 3;
    text-align: center;
    font-family: "Montserrat", sans-serif;
}

.modal-inner {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.modal-form {
    margin: 0 auto;
    max-width: 300px;
}

.modal p {
    text-transform: uppercase;
}

.modal-form button {
    border: 0px;
    margin: 12px auto;
    transform: 0.5s ease;
    text-decoration: none;
    padding: 10px 10px;
    border-radius: 9px;
    background-color: rgb(110, 110, 110);
    color: white;
    width: 100%;
    display: block;
    text-transform: uppercase;
    max-width: 180px;
    font-size: 14px;
    font-weight: 500;
}

.modal-form button.disabled {
    background-color: #ddd;
    color: #777;
    box-shadow: none;
}

button.primary {
    background-color: #03a9f4;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.12),0 1px 5px 0 rgba(0,0,0,.2);
    color: white;
}

button {
    font-family: "Montserrat";
}

.modal-form input {
    box-shadow: none;
    margin: 15px 0;
    padding: 12px 14px;
    width: 100%;
    font-size: 16px;
    outline: none;
    border: 1px solid #bbb;
    border-radius: 9px;
    display: inline-block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -ms-transition: 0.2s ease all;
    -o-transition: 0.2s ease all;
    transition: 0.2s ease all;
    font-family: "Montserrat";
    font-weight: 500;
}
